import React from 'react';

/**
 * This component can be used instead of GatsbyImage in case you don't want the wrapper
 **/
const Image = ({ image, className, style, alt = '', onLoad }) => {
  return image.images ? (
    <picture>
      {image.images.sources.map(source => (
        <source key={source.type} {...source} />
      ))}
      <img
        className={className}
        alt={alt}
        style={style}
        onLoad={onLoad}
        {...image.images.fallback}
      />
    </picture>
  ) : (
    <img
      className={className}
      alt={alt}
      style={style}
      onLoad={onLoad}
      src={image.publicURL}
    />
  );
};

export default Image;
