import React, { useState, memo } from 'react';
import MenuIcon from '../assets/menu.svg';
import { Link } from 'gatsby';
import classnames from 'classnames';
import { isBreakpoint } from '../utils';
import { useWindowSize, useBreakpoint, useMenuScroll } from '../utils/hooks';
import { SimpleContainer } from './Container';

import * as css from './Menu.module.css';
import * as grid from '../styles/grid.module.css';

const menuItems = [
  { href: '/', label: 'Home' },
  { href: '/#work', label: 'Work' },
  { href: '/#about', label: 'About' }
];

// these control how tall the menu is, and wether
const menuHeight = 112;
const threshold = 300;

const Menu = ({ className }) => {
  const [winWidth] = useWindowSize();
  const breakpoint = useBreakpoint(winWidth);

  const [showMenu, scrollTop] = useMenuScroll({ menuHeight, threshold });
  const [menuOpen, setMenuOpen] = useState(false);

  const onToggleMenu = e => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  return (
    <SimpleContainer tag="header" className={css.root} tagClassName={className}>
      <div
        className={classnames(grid.col, css.full, {
          [css.open]: menuOpen,
          [css.sticky]:
            !isBreakpoint(breakpoint, 'reduced') && showMenu !== null,
          [css.animate]: scrollTop > menuHeight + 30,
          [css.show]: showMenu === true
        })}
      >
        <ul className={css.menu}>
          {menuItems.map((item, index) => {
            return isBreakpoint(breakpoint, 'reduced') ? (
              <li key={index}>
                <SimpleContainer bottomLine className={css.row}>
                  <Link to={item.href}>{item.label}</Link>
                </SimpleContainer>
              </li>
            ) : (
              <li key={index}>
                <Link to={item.href}>{item.label}</Link>
              </li>
            );
          })}
        </ul>
      </div>
      <a
        href="#"
        className={classnames(grid.col, css.burger, {
          [css.sticky]:
            isBreakpoint(breakpoint, 'reduced') && showMenu !== null,
          [css.animate]: scrollTop > menuHeight + 30,
          [css.show]: showMenu === true
        })}
        onClick={onToggleMenu}
      >
        <MenuIcon />
      </a>
    </SimpleContainer>
  );
};

export default memo(Menu);
