import React, { memo, useRef, useEffect, useMemo } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { graphql, useStaticQuery } from 'gatsby';

import lineInfo from '../fonts/line.json';
import { passiveArg, map } from '../utils';
import {
  useWindowSize,
  useImages,
  useBreakpoint,
  useBreakpointValue,
  useLineRender
} from '../utils/hooks';

import * as css from './GridFlip.module.css';

const imagesQuery = graphql`
  query {
    images: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "home" }
      }
    ) {
      nodes {
        name
        publicURL
        extension
        childImageSharp {
          gatsbyImageData(width: 1200)
        }
      }
    }
  }
`;

// TODO: We might need to -1 on the margin bottoms to fit the baseline
const breakpointValues = {
  small: {
    topModules: 3,
    bottomModules: 1,
    marginBottom: -(8 * 2),
    images: [
      { x: 0, y: 4, width: 3, height: 2 },
      { x: 2, y: 6, width: 1, height: 1 },
      { x: 1, y: 7, width: 3, height: 2 },
      { x: 1, y: 9, width: 2, height: 1 },
      { x: 0, y: 10, width: 4, height: 2 }
    ]
  },
  reduced: {
    topModules: 3,
    bottomModules: 1,
    marginBottom: -(8 * 2),
    images: [
      { x: 2, y: 4, width: 4, height: 2 },
      { x: 2, y: 6, width: 1, height: 1 },
      { x: 0, y: 7, width: 4, height: 2 },
      { x: 1, y: 9, width: 2, height: 1 },
      { x: 2, y: 10, width: 4, height: 2 }
    ]
  },

  medium: {
    topModules: 3,
    bottomModules: 1,
    marginBottom: -(8 * 4),
    images: [
      { x: 0.4, y: 4, width: 0.55, height: 3 },
      { x: 0.4, y: 6, width: 2, height: 2, alignRight: true },
      { x: 1, y: 8, width: 0.4, height: 3 },
      { x: 0.5, y: 10, width: 0.4, height: 2 },
      { x: 0, y: 12, width: 0.7, height: 3 }
    ]
  },
  largest: {
    topModules: 3,
    bottomModules: 2,
    marginBottom: -(8 * 16),
    images: [
      { x: 0.4, y: 4, width: 0.5, height: 4 },
      { x: 0.4, y: 6, width: 2, height: 2, alignRight: true },
      { x: 1, y: 8, width: 0.4, height: 3 },
      { x: 0.5, y: 10, width: 0.3, height: 2 },
      { x: 0, y: 12, width: 0.7, height: 4 }
    ]
  }
};

const GridFlip = () => {
  const [winWidth] = useWindowSize();
  if (winWidth) {
    return <GridFlipClient winWidth={winWidth} />;
  } else {
    // TODO: This should be fixed so the internal links work.
    return null;
  }
};

const GridFlipClient = memo(({ winWidth }) => {
  const ref = useRef();
  const data = useStaticQuery(imagesQuery);
  const images = useImages(data.images.nodes);
  const breakpoint = useBreakpoint(winWidth);
  const breakpointValue = useBreakpointValue(breakpoint, breakpointValues);
  const [key, id] = useLineRender();

  const [{ progress }, animation] = useSpring(
    () => ({
      progress: 0,
      default: { immediate: true }
    }),
    []
  );

  // This should really go away when the kerning is correct
  // This is caused by the flipped U not being kerned correctly.
  const patternWidth = lineInfo.patternWidth - 1;

  // Animation
  // ----------------------------------------------------

  const {
    svgWidth,
    svgHeight,
    midModuleX,
    rightModuleX,
    topSvgHeight,
    moveLeft
  } = useMemo(() => {
    // Calculate width numbers
    const targetWidth = winWidth * 1.2;
    const numHalfWidth = Math.ceil(targetWidth / 2 / patternWidth);
    const midModuleX = numHalfWidth * patternWidth;
    const rightModuleX = (numHalfWidth + 1) * patternWidth;
    const svgWidth = numHalfWidth * 2 * patternWidth + lineInfo.fontSize;
    const moveLeft = (svgWidth - winWidth) / 2;

    // Calculate height numbers based on the last image y + height
    const topSvgHeight = patternWidth * breakpointValue.topModules;
    const bottomSvgHeight = patternWidth * breakpointValue.bottomModules;

    const lastImageValue =
      breakpointValue.images[breakpointValue.images.length - 1];
    const svgHeight =
      (lastImageValue.y + lastImageValue.height) * patternWidth +
      bottomSvgHeight +
      lineInfo.fontSize;

    return {
      svgWidth,
      svgHeight,
      midModuleX,
      rightModuleX,
      topSvgHeight,
      moveLeft
    };
  }, [winWidth, breakpointValue, patternWidth]);

  const transform = progress.to(p => {
    const deg = map(p, 0, 1, 0, 55);
    return `translateX(-${moveLeft}px) perspective(800px) rotateX(-${deg}deg)`;
  });

  // Scroll listener
  // ----------------------------------------------------

  useEffect(() => {
    const startFlipImage = breakpointValue.images[3];
    const offset = startFlipImage.y * patternWidth;

    const onScroll = e => {
      const elBounds = ref.current.getBoundingClientRect();
      const p = map(elBounds.top, -offset, -svgHeight, 0, 1);
      animation.set({ progress: p });
    };
    onScroll();
    window.addEventListener('scroll', onScroll, passiveArg);
    return () => window.removeEventListener('scroll', onScroll, passiveArg);
  }, [ref, animation, svgHeight, breakpointValue, patternWidth]);

  return (
    <div
      className={css.root}
      ref={ref}
      style={{ marginBottom: breakpointValue.marginBottom }}
    >
      {winWidth && (
        <animated.div
          className={css.invertedStarWars}
          style={{ transform, height: svgHeight, width: svgWidth }}
        >
          <svg
            key={key}
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            width="100%"
            height="100%"
          >
            <defs>
              <pattern
                id={id}
                x={1}
                y={0}
                width={patternWidth}
                height={patternWidth}
                patternUnits="userSpaceOnUse"
              >
                <g
                  className={css.square}
                  fontSize={lineInfo.fontSize}
                  letterSpacing={lineInfo.letterSpacing}
                >
                  <text x={0} y={lineInfo.fontSize}>
                    CUPERBRIGHTHFILALDWM
                  </text>
                  <text
                    x={2}
                    y={lineInfo.fontSize - 0.7}
                    transform={`rotate(90, ${0}, ${lineInfo.fontSize})`}
                  >
                    UPERBRIGHTHFILALDWM
                  </text>
                </g>
              </pattern>
            </defs>
            <rect
              fill={`url(#${id})`}
              x={midModuleX}
              y={patternWidth}
              width={patternWidth}
              height={patternWidth * 2}
            />
            <rect
              fill={`url(#${id})`}
              x={rightModuleX}
              width={svgWidth - rightModuleX}
              height={topSvgHeight}
            />
            <rect
              fill={`url(#${id})`}
              y={topSvgHeight}
              width="100%"
              height={svgHeight - topSvgHeight}
            />
          </svg>
          {breakpointValue.images.map((values, i) => (
            <GridImage
              key={`grid-image-${i}`}
              {...values}
              image={images[(i + 1).toString()]}
              patternWidth={patternWidth}
              svgWidth={svgWidth}
            />
          ))}
        </animated.div>
      )}
    </div>
  );
});

/**
 * Renders an image in the grid flip
 **/
const adjust = 9;
const margin = 4;

const GridImage = memo(props => {
  const { image, patternWidth, svgWidth, alignRight } = props;

  const xModules = percentageToModule(props.x, svgWidth, patternWidth);
  let x = xModules * patternWidth + adjust + margin;

  const widthModules = percentageToModule(props.width, svgWidth, patternWidth);
  const width = widthModules * patternWidth - adjust - margin * 2;

  if (alignRight) {
    x -= widthModules * patternWidth;
  }

  const y = props.y * patternWidth + adjust + margin;
  const height = props.height * patternWidth - adjust - margin * 2;

  return image.images ? (
    <picture>
      {image.images.sources.map(source => (
        <source key={source.type} {...source} />
      ))}
      <animated.img
        className={css.gridImage}
        alt=""
        style={{
          width,
          height,
          transform: `translate(${x}px, ${y}px)`
        }}
        {...image.images.fallback}
      />
    </picture>
  ) : (
    <animated.img
      className={css.gridImage}
      alt=""
      style={{ width, height, transform: `translate(${x}px, ${y}px)` }}
      src={image.publicURL}
    />
  );
});

const percentageToModule = (val, svgWidth, patternWidth) => {
  if (val < 1) {
    return Math.round((svgWidth * val) / patternWidth);
  }
  return val;
};

export default GridFlip;
