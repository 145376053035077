import React, { useState, useMemo } from 'react';
import classnames from 'classnames';

import SectionHeading from './SectionHeading';
import { SimpleContainer, SidesContainer } from '../components/Container';
import { useFirstRender } from '../utils/hooks';

import * as css from './Text.module.css';
import * as grid from '../styles/grid.module.css';

const Text = ({ title, id, children, large, split }) => {
  const firstRender = useFirstRender();
  const [open, setOpen] = useState(false);

  // Split the content
  const [first, rest] = useMemo(() => {
    const first = [];
    let rest = [];
    if (split) {
      React.Children.forEach(children, (child, i) => {
        i < split ? first.push(child) : rest.push(child);
      });
    } else {
      rest = rest.concat(children);
    }
    return [first, rest];
  }, [split, children]);

  const restWithColumns = !large || rest.length > 1;

  return (
    <div id={id || title?.toLowerCase().replace(/\s+/g, '-')}>
      {title && (
        <>
          <SimpleContainer
            bottomLine
            className={css.beforeTitle}
          ></SimpleContainer>

          <SectionHeading bottomLine>{title}</SectionHeading>
        </>
      )}
      <SidesContainer
        bottomLine
        ms={firstRender ? undefined : 550}
        recalculate={open}
      >
        {first.length > 0 && (
          <div
            className={classnames(grid.col, css.content, {
              [css.large]: large,
              [css.columns]: !large
            })}
          >
            {first}
            {rest.length > 0 && (
              <button className={css.more} onClick={() => setOpen(!open)}>
                {!open ? 'Read more +' : 'Collapse –'}
              </button>
            )}
          </div>
        )}
        {rest.length > 0 && (
          <div
            className={classnames(grid.col, css.content, {
              [css.collapsed]: first.length && !open,
              [css.large]: !restWithColumns
            })}
          >
            <div
              className={classnames({
                [css.columns]: restWithColumns,
                [css.afterMore]: first.length
              })}
            >
              {rest}
            </div>
          </div>
        )}
      </SidesContainer>
    </div>
  );
};

export default Text;
