import React from 'react';
import Line from './Line';

import * as css from './Spacer.module.css';

export const Spacer = ({ height = 1 }) => {
  return (
    <div
      className={css.root}
      style={{
        height: 112 * height - 8
      }}
    >
      <Line isFlexbox spacing />
    </div>
  );
};

export default Spacer;
