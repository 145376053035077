import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import MainLayout from '../layouts/Main';
import Head from '../components/Head';
import Intro from '../components/Intro';
import About from '../components/About';
import TeamViewer from '../components/TeamViewer';
import TeamList from '../components/TeamList';
import GridFlip from '../components/GridFlip';
import Teaser from '../components/Teaser';
import WorkList from '../components/WorkList';
import { usePreparedWorkItems } from '../utils/hooks';

import Spacer from '../components/Spacer';

const SHOWREEL = {
  show: true,
  vimeoId: '671205956',
  vimeoCode: '4f6bf475b0'
};

const SHOW_TEAMVIEWER = false;
const SHOW_TEAMLIST = true;

const PEOPLE = [
  {
    name: 'Igal Nassima',
    title: 'Founder / CEO',
    email: 'igal@superbright.me'
  },
  {
    name: 'Neil Evely',
    title: 'Head of Business',
    email: 'neil@superbright.me'
  },
  {
    name: 'Erica Newmann',
    title: 'Head of Production',
    email: 'erica@superbright.me'
  }
];

const IndexPage = () => {
  const data = useStaticQuery(query);
  const items = usePreparedWorkItems(data.work.nodes, data.images.nodes);
  return (
    <MainLayout>
      <Head />
      <Intro />
      <Teaser />
      <GridFlip />
      <WorkList items={items} />
      <Spacer height={1} />
      <About showreel={SHOWREEL} />
      {SHOW_TEAMVIEWER && <TeamViewer />}
      {SHOW_TEAMLIST && <TeamList people={PEOPLE} />}
    </MainLayout>
  );
};

const query = graphql`
  query {
    work: allFile(
      filter: { fields: { section: { eq: "work" } } }
      sort: {
        fields: childrenJavascriptFrontmatter___frontmatter___order
        order: ASC
      }
    ) {
      nodes {
        name
        childJavascriptFrontmatter {
          frontmatter {
            title
            brief
            description
            capabilities
          }
        }
        fields {
          slug
        }
      }
    }
    images: allFile(
      filter: {
        name: { regex: "/^poster/" }
        relativeDirectory: { regex: "/work/" }
        sourceInstanceName: { eq: "images" }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        relativeDirectory
        extension
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;

export default IndexPage;
