// extracted by mini-css-extract-plugin
export var beforeItem = "WorkList-module--beforeItem--2FWj8";
export var heading = "WorkList-module--heading--3Gklv";
export var title = "WorkList-module--title--2cS8I";
export var mobileLine = "WorkList-module--mobileLine--3GN-G";
export var description = "WorkList-module--description--1mS4c";
export var capabilities = "WorkList-module--capabilities--2g0ol";
export var link = "WorkList-module--link--2IXNh";
export var arrow = "WorkList-module--arrow--bWita";
export var capability = "WorkList-module--capability--1n8IW";
export var largeImage = "WorkList-module--largeImage--IwS6b";
export var smallImage = "WorkList-module--smallImage--1Hurp";
export var showImages = "WorkList-module--showImages--2wFRH";
export var extra = "WorkList-module--extra--W3qml";