// extracted by mini-css-extract-plugin
export var headingContainer = "About-module--headingContainer--38a9c";
export var heading = "About-module--heading--2UsXL";
export var reel = "About-module--reel--VWPAt";
export var reelPlayer = "About-module--reelPlayer--2k668";
export var reelClose = "About-module--reelClose--193Zf";
export var reelButton = "About-module--reelButton--2I3Pq";
export var reelText = "About-module--reelText--1HXI0";
export var reelBackground = "About-module--reelBackground--39pJy";
export var arrow = "About-module--arrow--VphGR";
export var capabilitiesText = "About-module--capabilitiesText--o8VrH";
export var capabilitiesList = "About-module--capabilitiesList--iiIBH";
export var capability = "About-module--capability--23tpy";