import React from 'react';
import classnames from 'classnames';

import { SimpleContainer } from '../components/Container';

import * as css from './SectionHeading.module.css';
import * as grid from '../styles/grid.module.css';

const SectionHeading = ({ children, bottomLine, topLine }) => {
  return (
    <SimpleContainer bottomLine={bottomLine} topLine={topLine}>
      <div className={classnames(grid.col, css.root)}>
        <h2>{children}</h2>
      </div>
    </SimpleContainer>
  );
};

export default SectionHeading;
