import React, { useState, memo } from 'react';

import { SimpleContainer, SidesContainer } from './Container';
import Text from '../content/Text';
import { StaticImage as Image } from 'gatsby-plugin-image';
import { Vimeo } from '../content/Video';
import ArrowRight from '../assets/arrow_right_filled.svg';
import Close from '../assets/close.svg';

import { useWindowSize, useBreakpoint } from '../utils/hooks';
import { isBreakpoint } from '../utils';

import * as css from './About.module.css';

const capabilities = [
  'Product Development',
  'Experience Design',
  'Volumetric Capture',
  'Software Engineering',
  'Real-Time',
  'AR, VR',
  'CG & VFX',
  'Photogrammetry'
];

const About = ({ showreel }) => {
  const [winWidth] = useWindowSize();
  const breakpoint = useBreakpoint(winWidth);
  const [isReelPlaying, setReelPlaying] = useState();

  return (
    <div id="about">
      <SidesContainer className={css.headingContainer} topLine bottomLine>
        <h1 className={css.heading}>about</h1>
      </SidesContainer>
      <Text>
        <p>
          Superbright was established in New York in 2015 by engineer, artist
          and NYU adjunct professor Igal Nassima, with the objective of
          exploring how immersive technology could be leveraged, in
          collaboration with artists and filmmakers, to help tell their stories
          in new ways and with a fresh perspective.
        </p>
        <p>
          As an expert in network engineering and having taught the “Desert of
          the Real” course at NYU for three years (a deep dive into Social VR)
          meant he was well placed to support commissions that required
          multiplayer experiences for Virtual and Augmented Reality projects.
        </p>
        <p>
          Opening a dedicated studio in New York allowed Superbright to grow,
          broaden our skill sets (CG, VFX, Animation) and take on a more diverse
          number of projects for a range of clients across brands, agencies,
          filmmakers and artists.
        </p>

        <p>
        We pride ourselves on our collaborative approach and building close long-term 
        relationships with our clients. As a result, we have helped design scalable 
        products that we continue to help develop, such as our partnership with Vortic.
        </p>
        <p>
        Coming out of this R&D, we have developed our own proprietary technologies 
        that include spatial audio creation, multiplayer VR and volumetric capture, 
        all of which can easily be adapted for new projects and platforms.
        </p>
        <p>
        In September 2021, Superbright opened the doors to a new London Studio 
        (near Old Street), expanding our network of partners and clients and continuing 
        to push boundaries in creative technology.
        </p>
      </Text>
      {showreel.show && (
        <SimpleContainer bottomLine className={css.reel}>
          <button
            className={css.reelButton}
            onClick={() => setReelPlaying(true)}
          >
            <Image
              src="../images/reel-button-background.jpg"
              alt=""
              objectPosition={'50% 15%'}
              className={css.reelBackground}
            />
            <div className={css.reelText}>
              <ArrowRight className={css.arrow} />
              Watch the Showreel
            </div>
          </button>
        </SimpleContainer>
      )}

      {isReelPlaying && (
        <div className={css.reelPlayer}>
          <button
            className={css.reelClose}
            onClick={() => {
              setReelPlaying(false);
            }}
          >
            <Close /> Close
          </button>
          <Vimeo
            vimeoId={showreel.vimeoId}
            vimeoCode={showreel.vimeoCode}
            autoplay
          />
        </div>
      )}

      {isBreakpoint(breakpoint, 'medium') && (
        <SidesContainer bottomLine>
          <div className={css.capabilitiesText}>
            <p>
              Our capabilities span a wide range of technical and creative
              disciplines:
            </p>
          </div>
        </SidesContainer>
      )}
      <SidesContainer bottomLine>
        {!isBreakpoint(breakpoint, 'medium') && (
          <div className={css.capabilitiesText}>
            <p>
              Our capabilities span a wide range of technical and creative
              disciplines:
            </p>
          </div>
        )}
        <div className={css.capabilitiesList}>
          {capabilities.map((capability, index) => (
            <span key={index} className={css.capability}>
              {capability}
            </span>
          ))}
        </div>
      </SidesContainer>
    </div>
  );
};

export default memo(About);
