import React from 'react';
import classnames from 'classnames';

import { SimpleContainer } from './Container';
import SectionHeading from '../content/SectionHeading';

import * as css from './TeamList.module.css';
import * as grid from '../styles/grid.module.css';

const TeamList = ({ people = [] }) => {
  return (
    <div id="team">
      <SectionHeading bottomLine>Our team</SectionHeading>
      {people.map((person, index) => (
        <SimpleContainer
          className={css.person}
          key={person.name}
          bottomLine={index < people.length - 1}
        >
          <div className={classnames(grid.col, css.name)}>
            <p>{person.name}</p>
          </div>
          <div className={classnames(grid.col, css.title)}>
            <p>{person.title}</p>
          </div>
          <div className={classnames(grid.col, css.email)}>
            <p>{person.email}</p>
          </div>
        </SimpleContainer>
      ))}
    </div>
  );
};

export default TeamList;
