import React, { memo, useMemo, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';

import RockVideo from './RockVideo';
import Menu from './Menu';
import logoInfo from '../fonts/logo.json';
import { useWindowSize } from '../utils/hooks';
import { inCubic } from '../utils/easings';
import { passiveArg } from '../utils';

import * as css from './Intro.module.css';

// When the logo starts scaling down
const scaledownStart = 0;

// When the logo stops scaling down
const scaledownEnd = 0.8;

// When the line characters start ticking
const tickStart = 0.4;

// When the line characters stop ticking
const tickEnd = 0.8;

// When the line starts moving up
const moveStart = 0;

// When the line ends moving up
const moveEnd = 0.75;

// The smallest that the large logo shows on small screens
const logoLargeMinWidth = 200;

// The largest that the large logo shows
const logoLargeMaxWidth = 700;

// Ratio between width and height for rock videos
const rock1Ratio = 1.6;

const Intro = () => {
  const [{ progress }, ref] = useSpring(
    () => ({
      progress: 0,
      default: { immediate: true }
    }),
    []
  );

  const [winWidth, winHeight] = useWindowSize();

  // Controls the length of the scroll animation
  const scrollArea = winWidth > 0 && winWidth < 600 ? 2000 : 4000;

  // Scroll listener
  // ----------------------------------------------------

  useEffect(() => {
    const onScroll = e => {
      const p = Math.max(
        0,
        // Adding winheight * 0.2 makes the content below appear before animation
        Math.min(1, window.scrollY / (scrollArea + winHeight * 0.2))
      );
      ref.set({ progress: p });
    };
    onScroll();
    window.addEventListener('scroll', onScroll, passiveArg);
    return () => window.removeEventListener('scroll', onScroll, passiveArg);
  }, [ref, winHeight, scrollArea]);

  // Logo animation vars
  // ----------------------------------------------------

  // These variables hold info for when the logo is at its largest
  // which is at the beginning of the animation
  const logoLargeWidth = Math.max(
    logoLargeMinWidth,
    Math.min(logoLargeMaxWidth, winWidth * 0.5)
  );
  const logoLargeScale = logoLargeWidth / logoInfo.superbrightWidth;
  const logoLargeHeight = logoInfo.fontSize * logoLargeScale;
  const logoLargeX =
    winWidth / 2 - (logoInfo.superbrightWidth * logoLargeScale) / 2;
  const logoLargeY = winHeight / 2 - logoLargeHeight; // a bit above middle

  // These variables hold info for the logo when it is at its smallest
  // which is at the end of the animation.
  const logoSmallX = winWidth / 2 - logoInfo.superbrightWidth / 2;
  const logoSmallY = logoLargeHeight / 2 + logoInfo.fontSize / 2;

  // Figure out how many characters fit in the space to the right
  // and make a string with characters for right and left.
  const [leftStr, rightStr] = useMemo(() => {
    let spaceLeft = logoSmallX;
    let i = 0;
    let leftStr = '';
    let rightStr = '';
    while (spaceLeft >= 0) {
      const rightIdx = (11 + i) % logoInfo.lineChars.length;
      const leftIdx =
        logoInfo.lineChars.length - 1 - (i % logoInfo.lineChars.length);
      const rightChar = logoInfo.charWidths[rightIdx];
      const leftChar = logoInfo.charWidths[leftIdx];
      rightStr += rightChar.char;
      leftStr += leftChar.char;
      spaceLeft -= rightChar.advanceWidth + logoInfo.letterSpacing;
      i++;
    }
    // reverse string
    leftStr = leftStr.split('').reverse().join('');
    return [leftStr, rightStr];
  }, [logoSmallX]);

  // Map progress to the scale of the SVG <g> element
  // Scale path from center: https://stackoverflow.com/questions/11671100/scale-path-from-center
  // First translate() is for scaling from the center. I have no idea why I have to do +1 on the y translation, but that makes it scale from vertical middle.
  // Then the shape is scaled
  // Second translate() is the actual positioning of the text in the vertical middle
  const groupTransform = progress
    .to({
      range: [scaledownStart, scaledownEnd],
      output: [logoLargeScale, 1],
      extrapolate: 'clamp'
    })
    .to(
      s =>
        `translate(${(1 - s * 1) * (winWidth / 2)} ${
          (1 - s) * (logoLargeHeight / 2 + 1)
        }) scale(${s}) translate(0 ${logoSmallY})`
    );

  // Map progress to the translateY of the SVG itself
  const svgTransform = progress
    .to(p => inCubic(p))
    .to({
      range: [moveStart, moveEnd],
      // End the logo slightly above the window top
      output: [logoLargeY, -(logoSmallY + logoInfo.fontSize)],
      extrapolate: 'clamp'
    })
    .to(y => `translateY(${y}px)`);

  const display = progress.to(p => 'block'); //(p === 1 ? 'none' : 'block'));

  const tickProgress = progress
    .to({
      range: [tickStart, tickEnd],
      output: [0, 1],
      extrapolate: 'clamp'
    })
    .to(p => inCubic(p));

  // Rock animation vars
  // ----------------------------------------------------

  // We could use useBreakpoint here, but it was a bit easier to just
  // work with the win object.
  const rock1Width = winWidth < 600 ? 400 : 810;
  const rock1Height = rock1Width * rock1Ratio;
  const rock1Y = winHeight / 2 - rock1Height / 2 - logoLargeHeight * 2;
  const rock1 = {
    width: rock1Width,
    left: logoLargeX + logoLargeWidth - rock1Width * 0.1,
    top: rock1Y,
    rotation: -30,
    moveX: -(winWidth * 0.4),
    moveY: -(rock1Y + rock1Height),
    moveRotation: 10
  };

  const rock2Width = winWidth < 600 ? 500 : 810;
  const rock2X = logoLargeX - rock2Width * 0.6;
  const rock2 = {
    width: rock2Width,
    left: rock2X,
    top: logoLargeY + logoLargeHeight,
    rotation: 30,
    moveX: -(rock2X + rock2Width),
    moveY: -(winHeight * 0.5),
    moveRotation: -30
  };

  let fixedBox = null;
  if (winWidth && winHeight) {
    fixedBox = (
      <animated.div className={css.fixedBox} style={{ display }}>
        <animated.svg
          width="100%"
          height={logoLargeHeight}
          className={css.svg}
          style={{
            transform: svgTransform,
            letterSpacing: logoInfo.letterSpacing
          }}
        >
          <animated.g transform={groupTransform}>
            <animated.text
              textAnchor="end"
              x={logoSmallX - logoInfo.letterSpacing}
              y={0}
              fontSize={logoInfo.fontSize}
              letterSpacing={logoInfo.letterSpacing}
            >
              {tickProgress
                .to({
                  range: [0, 1],
                  output: [leftStr.length, 0],
                  extrapolate: 'clamp'
                })
                .to(idx => {
                  return leftStr.substring(Math.floor(idx));
                })}
            </animated.text>
            <text
              x={logoSmallX}
              y={0}
              fontSize={logoInfo.fontSize}
              letterSpacing={logoInfo.letterSpacing}
            >
              CUPERBRIGHT
            </text>
            <animated.text
              x={logoSmallX + logoInfo.superbrightWidth}
              y={0}
              fontSize={logoInfo.fontSize}
              letterSpacing={logoInfo.letterSpacing}
            >
              {tickProgress
                .to({
                  range: [0, 1],
                  output: [0, rightStr.length],
                  extrapolate: 'clamp'
                })
                .to(idx => {
                  return rightStr.substring(0, Math.ceil(idx));
                })}
            </animated.text>
          </animated.g>
        </animated.svg>
        <RockVideo rock="rock01" progress={progress} {...rock1} />
        <RockVideo rock="rock02" progress={progress} {...rock2} />
      </animated.div>
    );
  }

  return (
    <div style={{ height: scrollArea }}>
      <Menu className={css.menu} />
      {fixedBox}
    </div>
  );
};

export default memo(Intro);
