import React, { memo, Fragment, useState } from 'react';
import { SimpleContainer, SidesContainer } from './Container';
import { Link } from 'gatsby';
import classnames from 'classnames';
import Image from './Image';
import { useWindowSize, useBreakpoint } from '../utils/hooks';
import { getBreakpointValue, isBreakpoint } from '../utils';
import Line from './Line';
import ArrowRight from '../assets/arrow_right_filled.svg';

import * as css from './WorkList.module.css';
import * as grid from '../styles/grid.module.css';

const WorkList = ({ items }) => {
  const [winWidth] = useWindowSize();
  const breakpoint = useBreakpoint(winWidth);
  return (
    <div id="work">
      <h1 className={css.heading}>
        <span>our</span> work
      </h1>
      {items.map((item, i) => {
        return (
          <Fragment key={item.slug}>
            {i !== 0 && (
              <SimpleContainer
                topLine
                className={css.beforeItem}
              ></SimpleContainer>
            )}

            <WorkListItem
              item={item}
              breakpoint={breakpoint}
              bottomLine={i === items.length - 1}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

const largeImageValues = {
  largest: {
    itemHeight: 39, // units
    itemTop: -13,
    itemRight: 8
  },
  large: {
    itemHeight: 27,
    itemTop: -13,
    itemRight: 2
  },
  medium: {
    itemHeight: 22,
    itemTop: -13,
    itemRight: 0
  },
  small: {
    itemHeight: 19,
    itemTop: 0,
    itemRight: 0
  }
};

// const smallImageValues = {
//   largest: {
//     heightMin: 23, // units
//     heightMax: 23 // units
//   },
//   large: {
//     heightMin: 25,
//     heightMax: 25
//   },
//   medium: {
//     heightMin: 14,
//     heightMax: 18
//   },
//   small: {
//     heightMin: 10,
//     heightMax: 12
//   }
// };

const WorkListItem = memo(({ item, breakpoint, bottomLine }) => {
  const [titleHeight, setTitleHeight] = useState(13 * 8);
  const [hover, setHover] = useState(false);

  let largeImage = null;
  let smallImage = null;

  const showImages = () => {
    if (!hover) setHover(true);
  };

  const hideImages = () => {
    if (hover) setHover(false);
  };

  if (breakpoint && item.posters.length > 0) {
    // Large image
    const largeInfo = getBreakpointValue(breakpoint, largeImageValues);

    const largeTop = largeInfo.itemTop * 8;
    const largeRight = largeInfo.itemRight * 8;

    // take the height of the title plus the largeTop offset
    const largeHeight = titleHeight - largeTop - 8;
    const largeWidth =
      largeHeight * (item.posters[0].width / item.posters[0].height) || null;

    largeImage = (
      <Image
        key={`${item.title}-img-large`}
        style={{
          top: largeTop,
          width: largeWidth,
          height: largeHeight,
          right: largeRight
        }}
        className={css.largeImage}
        image={item.posters[0]}
        alt={`Poster image for ${item.title}`}
      />
    );

    // Small image
    if (item.posters.length > 1) {
      const smallHeight = titleHeight - 16;
      const smallWidth =
        smallHeight * (item.posters[1].width / item.posters[1].height) || null;
      const smallTop = 8;
      smallImage = (
        <Image
          key={`${item.title}-img-small`}
          loading="eager"
          style={{
            top: smallTop,
            width: smallWidth,
            height: smallHeight,
            // this should be + 8 but + 10 feels better optically
            right: largeRight + largeWidth + 10
          }}
          className={css.smallImage}
          image={item.posters[1]}
          alt={`Poster image for ${item.title}`}
        />
      );
    }
  }

  return (
    <div id={item.slug}>
      <SidesContainer topLine onHeightChange={setTitleHeight}>
        <div
          onMouseEnter={showImages}
          onMouseLeave={hideImages}
          role="presentation"
          className={classnames(grid.col, css.title, {
            [css.showImages]: hover
          })}
        >
          <Link to={item.slug}>
            <h2>{item.title}</h2>
          </Link>
          <Line className={css.mobileLine} />
          {largeImage} {smallImage}
        </div>
      </SidesContainer>
      <SidesContainer topLine>
        <div
          className={classnames(grid.col, css.description)}
          onMouseEnter={showImages}
          onMouseLeave={hideImages}
          role="presentation"
        >
          <p>
            <Link to={item.slug}>{item.description}</Link>
          </p>
        </div>
        {!isBreakpoint(breakpoint, 'medium') && (
          <div className={classnames(grid.col, css.capabilities)}>
            {item.capabilities &&
              item.capabilities.map((cap, i) => (
                <span key={`cap-${i}`} className={css.capability}>
                  {cap}
                </span>
              ))}
          </div>
        )}
      </SidesContainer>

      {isBreakpoint(breakpoint, 'medium') && (
        <SidesContainer topLine>
          <div className={classnames(grid.col, css.capabilities)}>
            {item.capabilities &&
              item.capabilities.map((cap, i) => (
                <span key={`cap-${i}`} className={css.capability}>
                  {cap}
                </span>
              ))}
          </div>
        </SidesContainer>
      )}

      <SimpleContainer topLine bottomLine={bottomLine}>
        <div className={css.link}>
          <Link to={item.slug}>
            Read more <span className={css.extra}>about the project</span>
            <ArrowRight className={css.arrow} />
          </Link>
        </div>
      </SimpleContainer>
    </div>
  );
});

export default memo(WorkList);
