// accelerating from zero velocity
export const inQuad = t => {
  return t * t;
};

// decelerating to zero velocity
export const outQuad = t => {
  return t * (2 - t);
};

// acceleration until halfway, then deceleration
export const inOutQuad = t => {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
};

// accelerating from zero velocity
export const inCubic = t => {
  return t * t * t;
};

// decelerating to zero velocity
export const outCubic = t => {
  return --t * t * t + 1;
};

// acceleration until halfway, then deceleration
export const inOutCubic = t => {
  return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
};

// accelerating from zero velocity
export const inQuart = t => {
  return t * t * t * t;
};

// decelerating to zero velocity
export const outQuart = t => {
  return 1 - --t * t * t * t;
};

// acceleration until halfway, then deceleration
export const inOutQuart = t => {
  return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
};

// accelerating from zero velocity
export const inQuint = t => {
  return t * t * t * t * t;
};

// decelerating to zero velocity
export const outQuint = t => {
  return 1 + --t * t * t * t * t;
};

// acceleration until halfway, then deceleration
export const inOutQuint = t => {
  return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
};
