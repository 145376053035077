import React, { memo, useRef, useEffect } from 'react';
import classnames from 'classnames';
import { useSpring } from '@react-spring/web';
import { passiveArg, map } from '../utils';
import {
  useWindowSize,
  useBreakpoint,
  useBreakpointValue
} from '../utils/hooks';

import { SidesContainer } from './Container';
import RockVideo from './RockVideo';

import * as css from './Teaser.module.css';
import * as grid from '../styles/grid.module.css';

const breakpointValues = {
  small: {
    width: 550,
    top: 220,
    left: -50
  },
  reduced: {
    width: 550,
    top: 130,
    right: 20
  },
  large: {
    width: 550,
    top: -30,
    right: 20
  },
  xlarge: {
    width: 650,
    right: 50,
    top: -70
  },
  contained: {
    width: 650,
    right: 50,
    top: -70
  },
  largest: {
    width: 800,
    right: 50,
    top: -20
  }
};

const Teaser = () => {
  const elRef = useRef();
  const [winWidth, winHeight] = useWindowSize();
  const breakpoint = useBreakpoint(winWidth);
  const breakpointValue = useBreakpointValue(breakpoint, breakpointValues);
  const [{ progress }, animationRef] = useSpring(
    () => ({
      progress: 0,
      default: { immediate: true }
    }),
    []
  );

  useEffect(() => {
    const onScroll = e => {
      const elBounds = elRef.current.getBoundingClientRect();
      const p = map(elBounds.top, winHeight, -(elBounds.height * 1.3), 0, 1);
      animationRef.set({ progress: p });
    };
    window.addEventListener('scroll', onScroll, passiveArg);
    return () => window.removeEventListener('scroll', onScroll, passiveArg);
  }, [elRef, animationRef, winHeight]);

  return (
    <div className={css.root} ref={elRef}>
      <SidesContainer topLine>
        <div className={classnames(grid.col, css.full)}>
          <h2 className={css.heading}>
            We are an experience design studio working with immersive
            technologies
          </h2>
        </div>
      </SidesContainer>
      <SidesContainer topLine tagClassName={css.rockParent}>
        <div className={classnames(grid.col, css.left)}>
          {breakpointValue && (
            <RockVideo
              progress={progress}
              moveY={300}
              rock="rock04"
              {...breakpointValue}
            />
          )}
        </div>
        <div className={classnames(grid.col, css.right)}>
          <p className={css.description}>
            We work across the{' '}
            <a href="#capabilities">full gamut of interactive technology</a> to
            create experiences that combine the physical with the digital.
          </p>
          <p className={css.description}>
            Our <a href="#team">multi-disciplinary team</a> of creatives and
            engineers look to challenge interface design by utilizing real-time
            technology and spatial methodologies.
          </p>
        </div>
      </SidesContainer>
    </div>
  );
};

export default memo(Teaser);
